// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { Container } from 'react-bootstrap';

const DataProtection = () => {
    return (
        <div>
            <Container className="content-container">
                <div className="d-flex flex-column">
                    <h3 className="justify-left">Data Protection</h3>
                    <br />
                    <p>
                        <strong>Erklärung zur Datenverarbeitung</strong>
                    </p>
                    <p>
                        Diese Erklärung zur Datenverarbeitung informiert darüber, wie wir mit Ihren Daten umgehen. Sie richtet sich an alle Nutzenden unserer
                        Website.
                    </p>
                    <br />
                    <p>
                        <strong>1. Art der personenbezogenen Daten und Zweck der Verarbeitung</strong>
                    </p>
                    <p>
                        Um den Betrieb unserer Website zu gewährleisten, speichern wir Datum; Uhrzeit des Zugriffs; IP-Adresse; Hostname des zugreifenden
                        Rechners; Website, von der aus die Website aufgerufen wurde; Websites, die über die Website aufgerufen werden; besuchte Seite auf
                        unserer Website; Meldung, ob der Abruf erfolgreich war; übertragene Datenmenge; Informationen über den Browsertyp und die verwendete
                        Version sowie das Betriebssystem.
                    </p>
                    <p>
                        Im Rahmen einer Vertragsbeziehung verarbeiten wir Ihre allgemeinen personenbezogenen Daten wie beispielsweise Vornamen, Namen,
                        Geburtstag, Geburtsort, Anschrift, E-Mail-Adresse sowie Telefonnummer.
                    </p>

                    <p>
                        Wenn es für die Durchführung der vertraglichen Beziehungen notwendig ist, speichern wir personenbezogene Daten der besonderen Kategorie
                        nach Art. 9 DSGVO wie unter anderem Gesundheitsdaten, Daten zu Ihrer sozialen Situation oder Herkunft.
                    </p>

                    <p>
                        <strong>2. Grundlage der Verarbeitung</strong>
                    </p>
                    <p>
                        Die Verarbeitung Ihrer personenbezogenen Daten erfolgt entweder auf Grundlage Ihrer Einwilligung, einer vertraglichen Beziehung, unseres
                        berechtigten Interesses oder aufgrund einer gesetzlichen Verpflichtung, jeweils abhängig vom konkreten Anlass.
                    </p>
                    <p>
                        <strong>3. Übermittlung personenbezogener Daten</strong>
                    </p>
                    <p>
                        Wir geben Ihre Daten nicht an Dritte weiter, es sei denn es für die Durchführung der Vertragsbeziehung notwendig. Unsere Website wird
                        bei Amazon Web Services EMEA SARL, Niederlassung Deutschland Marcel-Breuer-Str. 12, 80807 München gehostet. Der Hoster empfängt die oben
                        genannten Daten als Auftragsverarbeiter.
                    </p>
                    <p>
                        <strong>4. Dauer der Datenverarbeitung</strong>
                    </p>
                    <p>
                        Soweit die Verarbeitung Ihrer Daten auf Ihrer Einwilligung beruht, verarbeiten wir diese bis zu Ihrem Widerruf. Sollte die Verarbeitung
                        aufgrund eines berechtigten Interesses erfolgen, löschen wir Ihre Daten, sobald sie für die Erreichung des Zwecks ihrer Erhebung nicht
                        mehr erforderlich sind. Im Falle einer vertraglichen Grundlage, verarbeiten wir Ihre Daten bis der Vertrag beendet wurde und die
                        gesetzlichen Aufbewahrungsfristen abgelaufen sind.
                    </p>

                    <p>
                        <strong>5. Ihre Rechte im Rahmen der Datenverarbeitung</strong>
                    </p>
                    <p>Sie haben das Recht:</p>
                    <ul>
                        <li>Auskunft über Ihre uns verarbeiteten personenbezogenen Daten zu verlangen</li>
                        <li>Berichtigung unrichtiger / Vervollständigung Ihrer personenbezogenen Daten zu verlangen</li>
                        <li>Löschung / Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen</li>
                        <li>
                            Ihre personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an
                            einen anderen Verantwortlichen zu verlangen
                        </li>
                        <li>Ihre Einwilligung zur Datenverarbeitung jederzeit zu widerrufen</li>
                        <li>sich bei der Aufsichtsbehörde zu beschweren.</li>
                    </ul>
                    <p>
                        Die Rechte können Sie durch eine entsprechende Mitteilung an <a href="mailto:datenschutz@finbalance.de">datenschutz@finbalance.de</a>{' '}
                        ausüben.
                    </p>

                    <p>
                        <strong>6. Verantwortlichkeit</strong>
                    </p>
                    <p>
                        Verantwortlich für die Datenverarbeitung ist finBalance GmbH. Unseren Datenschutzbeauftragten erreichen Sie per E-Mail unter{' '}
                        <a href="mailto:datenschutz@finbalance.de">datenschutz@finbalance.de</a>.
                    </p>
                    <p>
                        <strong>7. Sicherheitsmaßnahmen und Dokumentationssysteme</strong>
                    </p>
                    <p>
                        Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten gegen Manipulation, Verlust, Zerstörung oder gegen
                        den Zugriff unberechtigter Personen zu schützen.
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default DataProtection;
