// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import MainLayout from '@components/layout/MainLayout';
import Faq from './Faq';
import HeroSection from './HeroSection';
import ProductSection from './ProductSection';
import CompanySection from './CompanySection';
import './WelcomePage.css';

const WelcomePage = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const id = location.hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, [location]);

    return (
        <div data-testid="welcome-page">
            <MainLayout>
                <HeroSection />
                <ProductSection />
                <CompanySection />
                <Faq />
            </MainLayout>
        </div>
    );
};

export default WelcomePage;
