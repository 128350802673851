import { Container, Row, Col, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import WelcomPhone from '@assets/images/HandyApp.png';

const HeroSection = () => {
    const { t } = useTranslation();
    return (
        <Container fluid className="hero-section">
            <>
                <Row>
                    <Col xs={6} className="section-left">
                        <div className="d-flex flex-column justify-content-center">
                            <h1 className="hero-section-title display-4">{t('welcomepage.product_section.product_name')}</h1>
                            <p className="fs-4 hero-section-text">{t('welcomepage.product_section.product_tagline')}</p>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <Image src={WelcomPhone} className="img-fluid hero-section-image" />
                    </Col>
                </Row>
            </>
        </Container>
    );
};

export default HeroSection;
