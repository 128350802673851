import * as React from 'react';
import { AppBar, Box, Toolbar, Container, Avatar, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@assets/images/settings.svg';
import NotificationIcon from '@assets/images/notification.svg';
import { useUser } from '@contexts/UserProvider';
import MenuComponent from '@components/menu/Menu';
import * as Styled from './styles';
import theme from 'src/theme';
import { useNavigate } from 'react-router-dom';
import { useUserSettingstore } from '@stores/useUserSettingsStore';

interface IAHeader {
    hideNavigation?: boolean;
}

const AppHeader: React.FC<IAHeader> = ({ hideNavigation }) => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const [menuId, setMenuId] = React.useState('');
    const user = useUser();
    const { t } = useTranslation();
    const { tab, setTab } = useUserSettingstore();
    const navigate = useNavigate();

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTab(t(newValue.toLowerCase()));
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>, id: string) => {
        setAnchorElUser(event.currentTarget);
        setMenuId(id);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        setMenuId('');
    };

    const isMenuOpen = (id: string) => menuId === id;

    const handleLogout = () => {
        user?.logout();
    };

    const userItems = [
        {
            label: t('profile'),
            onClick: () => console.log('User profile'),
        },
        {
            label: t('logout'),
            onClick: () => handleLogout(),
        },
    ];

    const settingsItems = [
        {
            label: 'Account',
            onClick: () => console.log('settings'),
        },
        {
            label: 'Something',
            onClick: () => console.log('settings'),
        },
    ];

    const notificationItems = [
        {
            label: 'Notification',
            onClick: () => console.log('Notification'),
        },
    ];

    const pages = [
        { label: t('wizards'), onClick: () => navigate('/') },
        { label: t('documents'), onClick: () => navigate('/documents') },
        { label: t('contacts'), onClick: () => navigate('/contacts') },
    ];

    const selectedTab = t(tab.toLowerCase());

    return (
        <AppBar position="static" color="secondary" sx={{ boxShadow: 'none', borderBottom: '1px solid #D7D7F0', background: theme.palette.common.white }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ display: { xs: hideNavigation ? 'flex' : 'none', md: 'flex' } }}>
                        <Styled.Logo aria-label="logo" />
                    </Box>
                    {!hideNavigation && (
                        <>
                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <MenuComponent
                                    tooltip={t('open_menu')}
                                    button={<MenuIcon />}
                                    menuItems={pages}
                                    anchorEl={anchorElUser}
                                    open={isMenuOpen('menu')}
                                    onClose={handleCloseUserMenu}
                                    onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'menu')}
                                />
                            </Box>
                            <Box sx={{ flexGrow: 1, ml: 6, display: { xs: 'none', md: 'flex' } }}>
                                <Tabs onChange={handleTabChange} value={selectedTab} aria-label="tabs">
                                    {pages.map((page) => (
                                        <Styled.MenuTab key={page.label} value={page.label} label={page.label} onClick={page.onClick} />
                                    ))}
                                </Tabs>
                            </Box>
                            <Styled.Search>
                                <Styled.SearchIconWrapper>
                                    <SearchIcon />
                                </Styled.SearchIconWrapper>
                                <Styled.StyledInputBase placeholder={t('search')} inputProps={{ 'aria-label': 'search' }} />
                            </Styled.Search>

                            <MenuComponent
                                tooltip={t('open_notifications')}
                                button={<img src={NotificationIcon} />}
                                buttonStyle={{ width: '40px', height: '40px', backgroundColor: '#F5F7FA', marginRight: '20px' }}
                                menuItems={notificationItems}
                                anchorEl={anchorElUser}
                                open={isMenuOpen('notifications')}
                                onClose={handleCloseUserMenu}
                                onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'notifications')}
                            />
                            <MenuComponent
                                tooltip={t('open_settings')}
                                button={<img src={SettingsIcon} />}
                                buttonStyle={{ width: '40px', height: '40px', backgroundColor: '#F5F7FA', marginRight: '20px' }}
                                menuItems={settingsItems}
                                anchorEl={anchorElUser}
                                open={isMenuOpen('settings')}
                                onClose={handleCloseUserMenu}
                                onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'settings')}
                            />
                            <MenuComponent
                                tooltip={t('open_user')}
                                button={<Avatar alt="Kristijan Bosnjak" src="/static/images/avatar/2.jpg" />}
                                menuItems={userItems}
                                anchorEl={anchorElUser}
                                open={isMenuOpen('user')}
                                onClose={handleCloseUserMenu}
                                onOpen={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOpenUserMenu(e, 'user')}
                            />
                        </>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default AppHeader;
