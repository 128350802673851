import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

interface ILoading {
    open: boolean;
}

const Loading: React.FC<ILoading> = ({ open }) => {
    return (
        <Backdrop data-testid="backdrop" sx={(theme) => ({ color: theme.palette.primary.main, zIndex: theme.zIndex.drawer + 1 })} open={open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default Loading;
