// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode, JwtPayload } from 'jwt-decode';
// import { CognitoIdentityProvider, GlobalSignOutCommand } from '@aws-sdk/client-cognito-identity-provider';
import { IUserContext } from '@interfaces/IUserContext';
import { IUser } from '@interfaces/IUser';
import { IProps } from '@interfaces/IProps';
import { COGNITO } from '@config/cognitoConfig';

const userContextState = {
    currentUser: {
        accessToken: '',
        refreshToken: '',
        isLoggedIn: false,
        accountId: '',
    },
    login: () => '',
    logout: () => '',
    signup: () => '',
    setRegistrated: () => '',
};

interface CustomJwtPayload extends JwtPayload {
    accountId: string;
}

const UserContext = React.createContext<IUserContext>(userContextState);

interface IToken {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
}

const UserProvider = ({ children }: IProps) => {
    const params = new URLSearchParams(location.search);
    const codeUrl = params.get('code');
    const [currentUser, setCurrentUser] = useState<IUser | null>();
    const [registrated, setRegistrated] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setCurrentUser(null);
    }, []);

    useEffect(() => {
        if (codeUrl) {
            localStorage.setItem('code', codeUrl);
        }
        if (localStorage.getItem('code')) {
            const getTokensByCode = async () => {
                const tokens: IToken = (await getTokens(localStorage.getItem('code')!))!;
                return tokens;
            };

            getTokensByCode()
                .then((result) => {
                    if (result?.access_token) {
                        const decoded = jwtDecode<CustomJwtPayload>(result.access_token);
                        const accountId = decoded.accountId;
                        setCurrentUser({ accessToken: result.access_token, refreshToken: result.refresh_token, isLoggedIn: true, accountId: accountId });
                    }
                })
                .catch((error) => console.log(error));
        }
    }, [localStorage.getItem('code'), registrated]);

    const getTokens = async (code: string) => {
        if (code) {
            const url = process.env.REACT_APP_AUTH_TOKEN!;

            const body = new URLSearchParams({
                grant_type: registrated ? 'refresh_token' : 'authorization_code',
                client_id: COGNITO.APP_CLIENT_ID!,
                code: code,
                redirect_uri: COGNITO.REDIRECT_SIGN_IN!,
                refresh_token: currentUser?.refreshToken ?? '',
            });

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: body.toString(),
            });

            const data = (await response.json()) as IToken;
            return data;
        } else {
            return null;
        }
    };

    const login = () => {
        const url = `https://${COGNITO.DOMAIN}/login?client_id=${COGNITO.APP_CLIENT_ID}&response_type=code&redirect_uri=${COGNITO.REDIRECT_SIGN_IN}`;
        window.location.href = url;
    };

    const signup = () => {
        const url = `https://${COGNITO.DOMAIN}/signup?client_id=${COGNITO.APP_CLIENT_ID}&response_type=code&redirect_uri=${COGNITO.REDIRECT_SIGN_IN}`;
        window.location.href = url;
    };

    const logout = () => {
        // window.location.href = `https://${COGNITO.DOMAIN}/logout?client_id=${COGNITO.APP_CLIENT_ID}&response_type=code&redirect_uri=${COGNITO.REDIRECT_SIGN_OUT}/`;
        // void (async () => {
        //     if (currentUser != null) {
        //         const command = new GlobalSignOutCommand({ AccessToken: currentUser.accessToken });
        //         const client = new CognitoIdentityProvider({ region: 'eu-central-1' });
        //         await client.send(command);
        //     }
        // })();
        localStorage.removeItem('code');
        setCurrentUser(null);
        navigate('/');
    };

    return <UserContext.Provider value={{ currentUser, login, logout, signup, setRegistrated } as IUserContext}>{children}</UserContext.Provider>;
};

export default UserProvider;

export const useUser = () => {
    const context = React.useContext(UserContext);

    if (context === null) {
        throw new Error('useUser must be used within a UserProvider');
    }

    return context;
};
