import { Container, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Faq = () => {
    const { t } = useTranslation();
    return (
        <Container fluid className="faq-section" id="faq">
            <h2 className="second-section-title text-light display-5 pt-5">{t('welcomepage.faq-section.title')}</h2>
            <Accordion flush className="mt-5 accordion-faq">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('welcomepage.faq-section.item-1.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-1.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('welcomepage.faq-section.item-2.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-2.text1')}</Accordion.Body>
                    <Accordion.Body>{t('welcomepage.faq-section.item-2.text2')}</Accordion.Body>
                    <Accordion.Body>{t('welcomepage.faq-section.item-2.text3')}</Accordion.Body>
                    <Accordion.Body>{t('welcomepage.faq-section.item-2.text4')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('welcomepage.faq-section.item-3.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-3.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header>{t('welcomepage.faq-section.item-4.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-4.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header>{t('welcomepage.faq-section.item-5.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-5.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header>{t('welcomepage.faq-section.item-6.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-6.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header>{t('welcomepage.faq-section.item-7.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-7.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                    <Accordion.Header>{t('welcomepage.faq-section.item-8.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-8.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                    <Accordion.Header>{t('welcomepage.faq-section.item-9.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-9.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                    <Accordion.Header>{t('welcomepage.faq-section.item-10.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-10.text')}</Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="a">
                    <Accordion.Header>{t('welcomepage.faq-section.item-11.title')}</Accordion.Header>
                    <Accordion.Body>{t('welcomepage.faq-section.item-11.text')}</Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    );
};

export default Faq;
