import { IModal } from '@interfaces/IModal';
import Modal from '../modal/Modal';
import Impressum from '../../pages/Impressum';

const ImpressumModal = ({ openModal, onClose }: IModal) => {
    return (
        <Modal hasCloseButton openModal={openModal} onClose={onClose} maxWidth="lg">
            <Impressum />
        </Modal>
    );
};

export default ImpressumModal;
