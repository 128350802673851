import { IModal } from '@interfaces/IModal';
import Modal from '../modal/Modal';
import DataProtection from 'src/pages/DataProtection';

const DataProtectionModal = ({ openModal, onClose }: IModal) => {
    return (
        <Modal hasCloseButton openModal={openModal} onClose={onClose} maxWidth="lg">
            <DataProtection />
        </Modal>
    );
};

export default DataProtectionModal;
