import * as React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { INotification } from '@interfaces/INotification';

const Notification: React.FC<INotification> = ({ message, open, severity = 'info', onClose, placeVertical = 'top', placeHorizontal = 'center' }) => {
    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={onClose} anchorOrigin={{ vertical: placeVertical, horizontal: placeHorizontal }}>
            <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Notification;
