// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { useLocation, Navigate } from 'react-router-dom';
import { useUser } from '@contexts/UserProvider';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const user = useUser();
    const location = useLocation();

    if (user?.currentUser === undefined) {
        return null;
    } else if (user?.currentUser) {
        return <>{children}</>;
    }

    const url = location.pathname + location.search + location.hash;
    return <Navigate to="/" state={{ next: url }} />;
};

export default PrivateRoute;
