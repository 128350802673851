import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { AlertColor } from '@mui/material';
import Notification from '@components/notification/Notification';
import { PlaceVertical, PlaceHorizontal, INotification } from '@interfaces/INotification';

interface NotificationContextType {
    showNotification: (message: string, severity?: AlertColor, placeVertical?: PlaceVertical, placeHorizontal?: PlaceHorizontal) => void;
}
interface NotificationProviderProps {
    children: ReactNode;
}

const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
    const [notification, setNotification] = useState<INotification>({
        message: '',
        severity: 'info',
        open: false,
        placeVertical: 'top',
        placeHorizontal: 'center',
    });

    const showNotification = useCallback(
        (message: string, severity: AlertColor = 'info', placeVertical: PlaceVertical = 'top', placeHorizontal: PlaceHorizontal = 'center') => {
            setNotification({ message, severity, open: true, placeVertical, placeHorizontal });
        },
        [],
    );

    const handleClose = () => {
        setNotification((prev) => ({ ...prev, open: false }));
    };

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
            <Notification
                open={notification.open}
                onClose={handleClose}
                placeVertical={notification.placeVertical}
                placeHorizontal={notification.placeHorizontal}
                severity={notification.severity}
                message={notification.message}
            />
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = (): NotificationContextType => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
