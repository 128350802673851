// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useUser } from '@contexts/UserProvider';

const SignButtons = () => {
    const { t } = useTranslation();
    const user = useUser();

    const handleLogin = () => {
        user.login();
    };

    const handleSignUp = () => {
        user.signup();
    };

    return (
        <div data-testid="signin-buttons">
            <Button type="button" variant="sign" onClick={handleLogin}>
                {t('common.login_button_label')}
            </Button>
            <Button type="button" variant="sign" className="ms-2" onClick={handleSignUp}>
                {t('common.signup_button_label')}
            </Button>
        </div>
    );
};

export default SignButtons;
