import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RowCard from '@components/rowCard/RowCard';
import ProductImage from '@assets/images/product_image_01.jpg';
import PrecautionImage from '@assets/images/vorsorge-1920-1080.png';
import SecurityImage from '@assets/images/sicherheit-1920-1080.png';
import CompletenessImage from '@assets/images/vollstaendig-1920-1080.png';
import CommunicationImage from '@assets/images/kommunikation-1920-1080.png';

const ProductSection = () => {
    const { t } = useTranslation();

    return (
        <Container fluid className="product-section" id="product">
            <>
                <h2 className="product-section-title text-light display-5">{t('welcomepage.product_section.title')}</h2>
                <>
                    <RowCard
                        title={''} // not using t('welcomepage.product_section.usp.title') as title
                        textList={[t('welcomepage.product_section.usp.text1'), t('welcomepage.product_section.usp.text2'), t('welcomepage.product_section.usp.text3')]}
                        img={ProductImage}
                        imgClassName="example-image"
                        altImage="example"
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                        hasBorder
                    />
                    <RowCard
                        title={t('welcomepage.product_section.topic1.title')}
                        textList={['', t('welcomepage.product_section.topic1.text1'), t('welcomepage.product_section.topic1.text2')]}
                        // hasListBullet
                        img={PrecautionImage}
                        imgClassName="example-image"
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                        imgFirst
                        hasBorder
                    />
                    <RowCard
                        title={t('welcomepage.product_section.topic2.title')}
                        textList={[
                            '',
                            t('welcomepage.product_section.topic2.text1'),
                            t('welcomepage.product_section.topic2.text2'),
                            t('welcomepage.product_section.topic2.text3'),
                            t('welcomepage.product_section.topic2.text4'),
                        ]}
                        // hasListBullet
                        img={SecurityImage}
                        imgClassName="example-image"
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                        hasBorder
                    />
                    <RowCard
                        title={t('welcomepage.product_section.topic3.title')}
                        textList={['', t('welcomepage.product_section.topic3.text1'), t('welcomepage.product_section.topic3.text2')]}
                        // hasListBullet
                        img={CompletenessImage}
                        imgClassName="example-image"
                        imgFirst
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                        hasBorder
                    />
                    <RowCard
                        title={t('welcomepage.product_section.topic4.title')}
                        textList={['', t('welcomepage.product_section.topic4.text1'), t('welcomepage.product_section.topic4.text2'), t('welcomepage.product_section.topic4.text3')]}
                        // hasListBullet
                        img={CommunicationImage}
                        imgClassName="example-image"
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                    />
                </>
            </>
        </Container>
    );
};

export default ProductSection;
