// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { Container } from 'react-bootstrap';

const Impressum = () => {
    return (
        <div>
            <Container className="content-container">
                <div className="d-flex flex-column">
                    <h3 className="justify-left">Impressum</h3>
                    <p>Angaben gemäß §5 TMG/DDG</p>
                    <p>
                        <strong>finBalance GmbH</strong>
                        <br />
                        c/o smartspaces
                        <br />
                        Eiswerderstrasse 16 Gebäude 127
                        <br />
                        13585 Berlin
                        <br />
                    </p>
                    <p>
                        <strong>Vertreten durch:</strong>
                        <br />
                        Ingo Brenckmann – Geschäftsführer
                        <br />
                        Mathias Pöhling – Geschäftsführer
                        <br />
                    </p>
                    <p>
                        <strong>Kontakt:</strong>
                        <br />
                        Telefon: +49 30 235 96480
                        <br />
                        E-Mail: hello@finbalance.de
                        <br />
                        Internet: www.finbalance.de
                    </p>
                    <p>
                        <strong>Registereintrag:</strong>
                        <br />
                        Sitz der Gesellschaft: Berlin
                        <br />
                        Registergericht: Amtsgericht Berlin-Charlottenburg
                        <br />
                        Registernummer: HBR 267957 B
                        <br />
                        Umsatzsteuer ID gemäß §27a UStG: DE370144128
                    </p>
                    <p>
                        <strong>Inhaltlich verantwortlich gemäß §18 Abs. 2 MStV:</strong>
                        <br />
                        Ingo Brenckmann
                        <br />
                        c/o smartspaces
                        <br />
                        Eiswerderstr. 16, Gebäude 127
                        <br />
                        13585 Berlin
                    </p>
                    <p>
                        <strong>Verbraucherinformationen zur alternativen Streitschlichtung:</strong>
                        <br />
                        Die Europäischen Union stellt eine Plattform zur Online-Streitbeilegung (sog. „OS-Plattform“) für Verbraucher bereit, diese ist unter
                        dem folgenden Link erreichbar:
                        <br />
                        <a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>
                    </p>
                    <p>Wir sind nicht bereit und nicht verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

                    <strong>Bildrechte:</strong>
                    <p>
                        Photo by Green Chameleon on <a href="unsplash.com">unsplash.com</a>
                    </p>
                    <p>
                        Photo by Hamsterfreund on <a href="pixabay.com">pixabay.com</a>
                    </p>
                    <p>
                        Photo by ua_Bob_Dmyt_ua on <a href="pixabay.com">pixabay.com</a>
                    </p>
                    <p>
                        Photo by Ross Sneddon on <a href="unsplash.com">unsplash.com</a>
                    </p>
                    <p>
                        Photo by Priscilla Du Preez on <a href="unsplash.com">unsplash.com</a>
                    </p>
                    <p>
                        Photo by Ian Schneider on <a href="unsplash.com">unsplash.com</a>
                    </p>
                    <p>
                        Photo by David Iskander on <a href="unsplash.com">unsplash.com</a>
                    </p>
                    <p>
                        Photo vom Eiswerder Campus by <a href="m13-architekten.de">m13-architekten.de</a>
                    </p>
                </div>
            </Container>
        </div>
    );
};

export default Impressum;
