interface IRowCardText {
    textList: string[];
    hasListBullet?: boolean;
}

const Section1 = ({ textList, hasListBullet }: IRowCardText) => {
    return (
        <>
            {textList.map((text) => (
                <ul className={`${!hasListBullet && 'without-bullets'}`} key={text}>
                    <li className="text-start text-light">{text}</li>
                </ul>
            ))}
        </>
    );
};

export default Section1;
