import { Box, IconButton, Tooltip, Menu, MenuItem, PopoverVirtualElement, ListItemIcon, Divider } from '@mui/material';

interface IMenu {
    button: React.ReactNode;
    menuItems: { label: string; icon?: string; onClick: () => void }[];
    anchorEl: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
    open: boolean;
    onClose: () => void;
    onOpen: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    buttonStyle?: React.CSSProperties;
    tooltip?: string;
    hasDivider?: boolean;
    width?: number;
    disabled?: boolean;
}

const MenuComponent: React.FC<IMenu> = ({ button, menuItems, anchorEl, open, onClose, onOpen, buttonStyle, tooltip, hasDivider, width, disabled }) => {
    return (
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={tooltip}>
                <span>
                    <IconButton name={tooltip} onClick={onOpen} sx={buttonStyle} disabled={disabled} aria-label={tooltip}>
                        {button}
                    </IconButton>
                </span>
            </Tooltip>
            <Menu
                sx={{
                    mt: '45px',
                    '& .MuiPaper-root': {
                        width: width,
                    },
                }}
                id="menu-user"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={onClose}
            >
                {menuItems.map((item, index) => (
                    <div key={item.label}>
                        <MenuItem
                            onClick={() => {
                                item.onClick();
                                onClose();
                            }}
                        >
                            {item.icon && (
                                <ListItemIcon>
                                    <img src={item.icon} height={24} />
                                </ListItemIcon>
                            )}
                            {item.label}
                        </MenuItem>
                        {hasDivider && index < menuItems.length - 1 && <Divider />}
                    </div>
                ))}
            </Menu>
        </Box>
    );
};

export default MenuComponent;
