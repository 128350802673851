import { create } from 'zustand';
import { get as getData } from '../common/api';
interface Salutation {
    firstName: string;
    lastName: string;
    displayName: string;
    termsAndConditionsAccepted: boolean | undefined;
}

interface UserSettingsStore {
    salutation: Salutation;
    updateSalutation: (data: Partial<Salutation>) => void;
    fetchUserSettings: (token: string) => Promise<void>;
    tab: string;
    setTab: (tab: string) => void;
}

export const useUserSettingstore = create<UserSettingsStore>((set) => ({
    salutation: {
        firstName: '',
        lastName: '',
        displayName: '',
        termsAndConditionsAccepted: false,
    },
    tab: 'wizards',

    setTab: (newTab) => {
        set({ tab: newTab });
    },

    updateSalutation: (data) =>
        set((state) => ({
            salutation: {
                ...state.salutation,
                ...data,
            },
        })),

    fetchUserSettings: async (token: string) => {
        const url = `${process.env.REACT_APP_API_URL}/accounts/v1/accounts`;

        try {
            const data: Salutation = await getData(url, token);
            set((state) => ({
                ...state,
                salutation: data,
            }));
        } catch (error) {
            console.log(error);
        }
    },
}));
