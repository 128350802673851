// // © 2024 finBalance - Ingo.Brenckmann@finbalance.de
import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/index';
import './App.css';
import initI18n from './i18n';

import UserProvider from '@contexts/UserProvider';
import NotificationProvider from '@contexts/NotificationProvider';

import PublicRoute from '@components/publicPrivateRoute/PublicRoute';
import PrivateRoute from '@components/publicPrivateRoute/PrivateRoute';

import WelcomePage from './pages/WelcomePage/WelcomePage';
import Loading from '@components/loading/Loading';
import ErrorBoundary from '@components/errorBoundary';

const Wizard = React.lazy(() => import('./pages/Wizard/Wizard'));
const Documents = React.lazy(() => import('./pages/Documents/Documents'));
const Contacts = React.lazy(() => import('./pages/Contacts/Contacts'));

async function setupApp() {
    await initI18n();
}

function App() {
    void setupApp();

    return (
        <div className="App">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <NotificationProvider>
                        <UserProvider>
                            <ErrorBoundary fallback={<div>Something went wrong. Please try again later.</div>}>
                                <Suspense fallback={<Loading open />}>
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={
                                                <PublicRoute>
                                                    <WelcomePage />
                                                </PublicRoute>
                                            }
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <PrivateRoute>
                                                    <Routes>
                                                        <Route path="/wizard" element={<Wizard />} />
                                                        <Route path="/documents" element={<Documents />} />
                                                        <Route path="/contacts" element={<Contacts />} />
                                                        <Route path="/*" element={<Navigate to="/wizard" />} />
                                                    </Routes>
                                                </PrivateRoute>
                                            }
                                        />
                                    </Routes>
                                </Suspense>
                            </ErrorBoundary>
                        </UserProvider>
                    </NotificationProvider>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
