// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import 'bootstrap-icons/font/bootstrap-icons.css';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Stack, Image, Nav, NavLink } from 'react-bootstrap';
import Logo from '@assets/images/logo.svg';
// import Facebook from '@assets/images/facebook.svg';
// import X from '@assets/images/x.svg';
// import Instagram from '@assets/images/instagram.svg';
// import LinkedIn from '@assets/images/linkedin.svg';
// import TikTok from '@assets/images/tiktok.svg';
import Phone from '@assets/images/phone_icon.svg';
import Email from '@assets/images/email.svg';
import './Footer.css';
import { useState } from 'react';
import ImpressumModal from './ImpressumModal';
import DataProtectionModal from './DataProtectionModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const Footer: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [openDataProtectionModal, setOpenDataProtectionModal] = useState(false);
    const [termsAndConditionsModal, setOpenTermsAndConditionsModal] = useState(false);

    const companyURL = `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT}/#unternehmen`;
    const productURL = `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT}/#product`;
    const faqURL = `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT}/#faq`;

    return (
        <footer>
            <Container fluid className="footer-container" id="kontakt">
                <ImpressumModal openModal={openModal} onClose={() => setOpenModal(false)} />
                <DataProtectionModal openModal={openDataProtectionModal} onClose={() => setOpenDataProtectionModal(false)} />
                <TermsAndConditionsModal openModal={termsAndConditionsModal} onClose={() => setOpenTermsAndConditionsModal(false)} />
                <Row className="footer-row gap-2">
                    <Col className="float-center">
                        <Stack className="gap-lg-3 footer-brand">
                            <Stack direction="horizontal" gap={2}>
                                <Image src={Logo} className="logo-icon" alt="logo" />
                                <strong className="ms-2">finBalance</strong>
                            </Stack>
                            <Stack direction="horizontal">
                                {/* We currently have not social media presence. Add Icons as presence will become available.
                                    <Nav>
                                    <NavLink href="https://facebook.com">
                                        <Image src={Facebook} className="social-icons" alt="facebook" />
                                    </NavLink>
                                    <NavLink>
                                        <Image src={X} className="social-icons" alt="x-social" />
                                    </NavLink>
                                    <NavLink>
                                        <Image src={Instagram} className="social-icons" alt="instagram" />
                                    </NavLink>
                                    <NavLink>
                                        <Image src={LinkedIn} className="social-icons" alt="linkedin" />
                                    </NavLink>
                                    <NavLink>
                                        <Image src={TikTok} className="social-icons" alt="tiktok" />
                                    </NavLink>
                                </Nav> */}
                            </Stack>
                        </Stack>
                    </Col>
                    <Col>
                        <Nav className="flex-column">
                            <h6 className="text-start ps-3 fw-bold">{t('website_navigation.company_label')}</h6>
                            <NavLink className="text-dark text-start" href={companyURL}>
                                {t('website_navigation.company_label')}
                            </NavLink>
                            <NavLink className="text-dark text-start" href={productURL}>
                                {t('website_navigation.product_label')}
                            </NavLink>
                            <NavLink className="text-dark text-start" href={faqURL}>
                                {t('website_navigation.faq_label')}
                            </NavLink>
                        </Nav>
                    </Col>
                    <Col>
                        {/* <Nav className="flex-column">
                            <h6 className="text-start ps-3 fw-bold">{t('support')}</h6>
                            <NavLink className="text-dark text-start" href="#">
                                {t('hilfecenter')}
                            </NavLink>
                            <NavLink className="text-dark text-start" href="#">
                                {t('shreib_x')}
                            </NavLink>
                            <NavLink className="text-dark text-start" href="#">
                                {t('feedback')}
                            </NavLink>
                        </Nav> */}
                    </Col>
                    <Col>
                        <Nav className="flex-column">
                            <h6 className="text-start ps-3 fw-bold">{t('website_navigation.contact_label')}</h6>
                            <NavLink className="text-dark text-start" href="#">
                                <Image src={Phone} className="me-2" alt="phone" />
                                {t('finbalance.company_phonenumber')}
                            </NavLink>
                            <NavLink className="text-dark text-start" href="mailto:hello@finbalance.de">
                                <Image src={Email} className="me-2" alt="email" />
                                {t('finbalance.company_email')}
                            </NavLink>
                        </Nav>
                    </Col>
                    <Col>
                        <Nav className="flex-column">
                            <h6 className="text-start ps-3 fw-bold">{t('footer.legal_label')}</h6>
                            <NavLink className="text-dark text-start" href="#" onClick={() => setOpenModal(true)}>
                                {t('footer.impressum_label')}
                            </NavLink>
                            <NavLink className="text-dark text-start" href="#" onClick={() => setOpenDataProtectionModal(true)}>
                                {t('footer.data_protection_label')}
                            </NavLink>
                            <NavLink className="text-dark text-start" href="#" onClick={() => setOpenTermsAndConditionsModal(true)}>
                                {t('footer.tnc_label')}
                            </NavLink>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
