import { Col, Row, Image } from 'react-bootstrap';
import RowCardText from './RowCardText';
import useIsMobile from '@hooks/useIsMobile';
import './RowCard.css';

interface IRow {
    title: string;
    titleColor?: string;
    textList: string[];
    img: string;
    imgClassName: string;
    imgFirst?: boolean;
    colXs1: number;
    colSm1: number;
    colXs2: number;
    colSm2: number;
    hasBorder?: boolean;
    hasListBullet?: boolean;
    altImage?: string;
}

const RowCard = ({ ...props }: IRow) => {
    const { title, titleColor, textList, img, imgClassName, imgFirst, colXs1, colSm1, colXs2, colSm2, hasBorder, hasListBullet, altImage } = props;
    const isMobile = useIsMobile();

    return (
        <Row className={`${hasBorder && 'has-border'} section-row align-items-center justify-content-center`}>
            {imgFirst || isMobile ? (
                <>
                    <Col xs={colXs1} sm={colSm1}>
                        <div className={isMobile ? 'float-center' : imgFirst ? 'float-start' : 'float-end'}>
                            <Image src={img} className={`${imgClassName} img-fluid row-card-image`} alt={altImage} />
                        </div>
                    </Col>
                    <Col xs={colXs2} sm={colSm2}>
                        <h3 className={`${titleColor && titleColor} ${hasListBullet && 'bullet-list-header'} text-start product-section-subtitle`}>{title}</h3>
                        <RowCardText hasListBullet={hasListBullet} textList={textList} />
                    </Col>
                </>
            ) : (
                <>
                    <Col xs={colXs1} sm={colSm1}>
                        <h3 className={`${titleColor && titleColor} ${hasListBullet && 'bullet-list-header'} text-start product-section-subtitle`}>{title}</h3>
                        <RowCardText hasListBullet={hasListBullet} textList={textList} />
                    </Col>
                    <Col xs={colXs2} sm={colSm2} className="float-end">
                        <div className="float-end">
                            <Image src={img} className={`${imgClassName} img-fluid row-card-image`} alt={altImage} />
                        </div>
                    </Col>
                </>
            )}
        </Row>
    );
};

export default RowCard;
