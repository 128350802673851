export const get = async <T>(url: string, token?: string): Promise<T> => {
    const response = await fetch(url, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
    }

    if (response.status === 204) {
        return null as T;
    }

    return (await response.json()) as T;
};

export const post = async <T, D>(url: string, data: D, token: string): Promise<T> => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    });

    if (!response.ok) {
        throw new Error(`Error posting data: ${response.statusText}`);
    }

    if (response.status === 204) {
        return null as T;
    }

    return (await response.json()) as T;
};

export const update = async <T, D>(url: string, data: D, token?: string): Promise<T> => {
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const headers = token ? { ...defaultHeaders, Authorization: `Bearer ${token}` } : defaultHeaders;

    const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(data),
    });

    if (response.status === 204) {
        return null as T;
    }

    const contentType = response.headers.get('content-type');

    if (!response.ok) {
        throw new Error(`Error updating data: ${response.statusText}`);
    }

    if (contentType?.includes('application/json')) {
        return (await response.json()) as T;
    } else {
        return null as T;
    }
};

export const deleteData = async <T>(url: string, token?: string): Promise<T> => {
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const headers = token ? { ...defaultHeaders, Authorization: `Bearer ${token}` } : defaultHeaders;

    const response = await fetch(url, {
        method: 'DELETE',
        headers: headers,
    });

    if (response.status === 204) {
        return null as T;
    }

    const contentType = response.headers.get('content-type');

    if (!response.ok) {
        throw new Error(`Error updating data: ${response.statusText}`);
    }

    if (contentType?.includes('application/json')) {
        return (await response.json()) as T;
    } else {
        return null as T;
    }
};
