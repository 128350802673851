import { IModal } from '@interfaces/IModal';
import Modal from '../modal/Modal';
import TermsAndConditions from '../../pages/TermsAndConditions';

const TermsAndConditionsModal = ({ openModal, onClose }: IModal) => {
    return (
        <Modal hasCloseButton openModal={openModal} onClose={onClose} maxWidth="lg">
            <TermsAndConditions />
        </Modal>
    );
};

export default TermsAndConditionsModal;
