// © 2024 finBalance <Ingo.Brenckmann@finbalance.de>

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '@contexts/UserProvider';
import SignButtons from '../signButtons/SignButtons';
import { Container, Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Logo from '@assets/images/logo.svg';
import './Header.css';

const Header = () => {
    const user = useUser();
    const Navigate = useNavigate();
    const [isSignedIn, setIsSignedIn] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setIsSignedIn(user?.currentUser ? true : false);
    }, [user, setIsSignedIn]);

    const handleLogout = () => {
        user?.logout();
    };

    return (
        <Navbar expand="lg" className="navbar-main px-4">
            <Container fluid>
                <Navbar.Brand href="/">
                    <Image src={Logo} className="logo-icon" alt="logo" />
                    <strong className="ms-2 text-white">finBalance</strong>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto my-2 my-lg-0" navbarScroll>
                        {isSignedIn ? (
                            <Nav.Link className="navbar-nav me-auto mb-2 mb-lg-0" onClick={() => Navigate('/dashboard')}>
                                {t('website_navigation.dashboard_label')}
                            </Nav.Link>
                        ) : (
                            <>
                                <Nav.Link className="text-white" href="#product">
                                    {t('website_navigation.product_label')}
                                </Nav.Link>
                                <Nav.Link className="text-white" href="#unternehmen">
                                    {t('website_navigation.company_label')}
                                </Nav.Link>
                                <Nav.Link className="text-white" href="#faq">
                                    {t('website_navigation.faq_label')}
                                </Nav.Link>
                                <Nav.Link className="text-white" href="#kontakt">
                                    {t('website_navigation.contact_label')}
                                </Nav.Link>
                            </>
                        )}
                    </Nav>
                    {isSignedIn ? (
                        <div className="justify-content-end">
                            <NavDropdown
                                title={<Image src={'https://gravatar.com/avatar/1e5fb3195dd2f0ec2b0ac3394786ab09?s=400&d=robohash&r=x&s=32'} roundedCircle />}
                                align="end"
                            >
                                <NavDropdown.Item>Profile</NavDropdown.Item>
                                <NavDropdown.Item onClick={handleLogout}>{t('common.logout_button_label')}</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    ) : (
                        <SignButtons />
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Header;
