import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { IModal } from '../../interfaces/IModal';

export default function Modal({ openModal, onClose, title, text, confirmButtonText, children, maxWidth, hasCloseButton }: IModal) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Dialog fullWidth maxWidth={maxWidth} fullScreen={fullScreen} open={openModal} onClose={onClose} aria-labelledby="responsive-dialog-title">
                {children ? (
                    <>
                        <DialogContent>{children}</DialogContent>
                        {hasCloseButton && (
                            <DialogActions>
                                <Button onClick={() => onClose(false)} autoFocus>
                                    {t('close')}
                                </Button>
                            </DialogActions>
                        )}
                    </>
                ) : (
                    <>
                        <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{text}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => onClose(false)} autoFocus>
                                {confirmButtonText}
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </React.Fragment>
    );
}
