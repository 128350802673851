import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RowCard from '@components/rowCard/RowCard';
import CompanyBuildingImage from '@assets/images/eiswerder-luft.jpeg';
import MissionImage from '@assets/images/mission-1920-1080.png';
import VisionImage from '@assets/images/vision-1920-1080.png';

const CompanySection = () => {
    const { t } = useTranslation();

    return (
        <Container fluid className="company-section" id="unternehmen">
            <>
                <h2 className="product-section-title text-light display-5">{t('welcomepage.company_section.title')}</h2>
                <>
                    <RowCard
                        title={t('welcomepage.company_section.vision_label')}
                        titleColor="text-light"
                        textList={['', t('welcomepage.company_section.vision_text')]}
                        img={VisionImage}
                        imgClassName="example-image"
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                    />
                    <RowCard
                        title={t('welcomepage.company_section.mission_label')}
                        titleColor="text-light"
                        textList={['', t('welcomepage.company_section.mission_text1'), t('welcomepage.company_section.mission_text2')]}
                        img={MissionImage}
                        imgFirst
                        imgClassName="example-image"
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                    />

                    <RowCard
                        title={t('welcomepage.company_section.company_profile_label')}
                        titleColor="text-light"
                        textList={['', t('welcomepage.company_section.company_profile_text1'), t('welcomepage.company_section.company_profile_text2')]}
                        img={CompanyBuildingImage}
                        imgClassName="example-image"
                        colXs1={12}
                        colSm1={6}
                        colXs2={12}
                        colSm2={6}
                    />
                </>
            </>
        </Container>
    );
};

export default CompanySection;
